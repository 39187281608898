import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient } from '@angular/common/http';
import { AppState } from '../../models/app-state';
import { IBackendApi } from './backend-api.interface';
import { baseHttpUrl } from '../../configuration';
import { FileIoTask } from '../../modules/weac/models/file-io-task';
import { FileIoTaskStep } from '../../modules/weac/models/file-io-task-step';
import { StaffCard } from 'fmcu-core-ng';

@Injectable()
export class BackendApiHttpService implements IBackendApi {
  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/login`,
      JSON.stringify({ username: username, password: password })
    );
  }

  prolongateToken(token: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/prolongate-token`,
      JSON.stringify({ token: token })
    );
  }

  isInitialized(): Observable<any> {
    return this.http.get<boolean>(`${this.apiUrl}/is-initialized`);
  }

  saveConfiguration(configuration: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/save-configuration`,
      JSON.stringify({ configuration: configuration })
    );
  }

  saveSystemConfiguration(systemConfiguration: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/save-system-configuration`,
      JSON.stringify({ system_configuration: systemConfiguration })
    );
  }

  getAppState(): Observable<AppState> {
    return this.http.get<AppState>(`${this.apiUrl}/app-state`);
  }

  resetToDefaultConfiguration(): Observable<any> {
    return this.http.get<AppState>(`${this.apiUrl}/default-configuration`);
  }

  getStatisticsVisits(from: Date, to: Date): Observable<any> {
    return this.http.get(`${this.apiUrl}/statistics-visits?from=${from.toJSON()}&to=${to.toJSON()}`);
  }

  resetStatisticsVisits(from: Date, to: Date): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/reset-statistics-visits`,
      JSON.stringify({ from: from.toJSON(), to: to.toJSON() })
    );
  }

  exec(code: string) {
    return this.http
      .post(`${this.apiUrl}/exec`, code)
      .subscribe(() => { }, e => this.onError('exec', e));
  }

  restartApplication(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/restart-application`, {});
  }

  rebootSystem(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/reboot-system`);
  }

  ioStartTask(task: FileIoTask): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/file-io-start-task`,
      JSON.stringify({ task: task })
    );
  }

  ioCancel(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/file-io-cancel`);
  }

  ioClear(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/file-io-clear`);
  }

  ioUploadFile(step: FileIoTaskStep, binaryString: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/file-io-upload-file`,
      JSON.stringify({ binaryString: binaryString, stepId: step.id, fileName: step.fileName })
    );
  }

  ioGetFile(): Observable<any> {
    return this.http.get(`${this.apiUrl}/file-io-get-file`, {
      responseType: 'blob'
    });
  }

  ioStartWeac(step: FileIoTaskStep): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/file-io-start-weac`,
      JSON.stringify({ step: step })
    );
  }

  uploadDisplayImageFile(
    fileName: string,
    base64String: string
  ): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/display/svg-file`,
      JSON.stringify({
        filename: fileName,
        base64_str: base64String
      })
    );
  }

  downloadDisplayImageFile(key: string): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/get-gate-display-image?displayKey=${key}`,
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  simulateConnectionServerError(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/simulate-server-connection-error`,
      JSON.stringify({})
    );
  }

  saveLocalBarcodes(staffCards: StaffCard[]): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/save-local-barcodes`,
      JSON.stringify(staffCards ? staffCards.map(x => x.toJson()) : [])
    );
  }

  updateStaffCardsFromServer(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/update_staff_cards_from_server`,
      {}
    );
  }

  setTeachingMode(x: boolean): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/set-teaching-mode`,
      JSON.stringify({ is_teaching_mode: x })
    );
  }

  randomWinnerReset(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/random-winner-reset`,
      JSON.stringify({})
    );
  }

  getRandomWinnerHistory(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/random-winner-history`,
      JSON.stringify({})
    );
  }

  setGateModeNormal(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/gate-mode-normal`,
      JSON.stringify({})
    );
  }

  setGateModeLocked(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/gate-mode-locked`,
      JSON.stringify({})
    );
  }

  setGateModeLockedEntry(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/gate-mode-locked-entry`,
      JSON.stringify({})
    );
  }

  setGateModeLockedExit(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/gate-mode-locked-exit`,
      JSON.stringify({})
    );
  }

  setGateModeServiceEntry(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/gate-mode-service-entry`,
      JSON.stringify({})
    );
  }

  setGateModeServiceExit(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/gate-mode-service-exit`,
      JSON.stringify({})
    );
  }

  setGateModeSelfTest(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/gate-mode-self-test`,
      JSON.stringify({})
    );
  }

  setGateModeOff(value: boolean): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/gate-mode-off`,
      JSON.stringify({ value: value })
    );
  }

  setGateSignal(objectId: string, count: number): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/set-gate-signal`,
      JSON.stringify({
        'objectId': objectId,
        'count': count
      })
    );
  }

  refreshSystemInfo(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/refresh-system-info`,
      JSON.stringify({})
    );
  }

  lightBarSimulateSensors(hEntryHValues: boolean[], hExitHValues: boolean[], isVEntry: boolean, isVExit: boolean): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/light-bar/simulate-sensors`,
      JSON.stringify({
        'h_entry_values': hEntryHValues,
        'h_exit_values': hExitHValues,
        'is_v_entry': isVEntry,
        'is_v_exit': isVExit,
      })
    );
  }

  updateLedPlayer(binaryString: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/update-led-player`,
      JSON.stringify({ binaryString: binaryString })
    );
  }

  weacReset(unit: number): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/weac/reset`,
      JSON.stringify({ unit: unit })
    );
  }

  weacSetReleasedBrake(unit: number, value: boolean): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/weac/set-released-brake`,
      JSON.stringify({ unit: unit, value: value })
    );
  }

  weacSetDoorSpeed(unit: number, value: number): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/weac/set-door-speed`,
      JSON.stringify({ unit: unit, value: value })
    );
  }

  getWeacDoorFiles(): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/weac/door-files`,
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  setWeacDoorFiles(id: string, base64String: string
  ): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/weac/door-files`,
      JSON.stringify({
        id: id,
        base64_str: base64String
      })
    );
  }

  private onError(source: string, e: any) {
    console.error(
      `BackendApiHttpService. Method '${source}' failed. ${JSON.stringify(e)}`
    );
  }

  getModelConfiguration(modelName: string, objectId?: string, apiPath?: string): Observable<any> {
    if (!modelName) {
      modelName = '';
    }

    const params = {};
    if (objectId) {
      params['objectId'] = objectId;
    }

    const path = !apiPath ? `${this.apiConfigurationUrl}/${modelName}` : apiPath;

    return this.http.get<any>(path,
      { params });
  }

  private get apiUrl(): string {
    return `${baseHttpUrl}/api`;
  }

  getModelSchemas(modelName: string): Observable<any> {
    if (!modelName) {
      modelName = '';
    }

    return this.http.get<any>(`${this.apiSchemaUrl}/${modelName}`);
  }

  saveModelConfiguration(modelName: string, configuration: any): Observable<any> {
    return this.http.post(
      `${this.apiConfigurationUrl}/${modelName}`,
      JSON.stringify({ configuration: configuration })
    );
  }

  updateModelConfiguration(modelName: string, objectId: string, configuration: any): Observable<any> {
    return this.http.put(
      `${this.apiConfigurationUrl}/${modelName}`,
      JSON.stringify({
        objectId: objectId,
        configuration: configuration,
      })
    );
  }

  importModelConfigurations(uploadModelConfigurations: any): Observable<any> {
    return this.http.post(
      `${this.apiConfigurationUrl}/import-model-configurations`,
      JSON.stringify({ import_model_configurations: uploadModelConfigurations })
    );
  }

  deleteModelConfiguration(modelName: string, objectId: string): Observable<any> {
    if (!modelName) {
      modelName = '';
    }

    const params = {};
    if (objectId) {
      params['objectId'] = objectId;
    }

    return this.http.delete<any>(`${this.apiConfigurationUrl}/${modelName}`,
      { params });
  }

  downloadConfiguration(): Observable<any> {
    return this.http.get<any>(`${this.apiConfigurationUrl}/download-configuration`);
  }

  uploadConfiguration(uploadConfiguration): Observable<any> {
    return this.http.post(
      `${this.apiConfigurationUrl}/upload-configuration`,
      JSON.stringify({
        uploadConfiguration: uploadConfiguration
      })
    );
  }

  openvpnStart(): Observable<any> {
    return this.http.post(`${this.apiUrl}/openvpn/start`, {});
  }

  openvpnStop(): Observable<any> {
    return this.http.post(`${this.apiUrl}/openvpn/stop`, {});
  }

  openvpnUploadKey(fileName: string, base64String: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/openvpn/upload-key`,
      JSON.stringify({
        fileName: fileName,
        binaryString: base64String
      }));
  }

  openvpnGetStatus(): Observable<any> {
    return this.http.get(`${this.apiUrl}/openvpn/status`, {});
  }

  installerSetConfiguration(filename: string, replaceDevices: boolean, reboot: boolean): Observable<any> {
    if (!replaceDevices) {
      replaceDevices = false;
    }
    return this.http.post(
      `${this.apiUrl}/installer/configuration`,
      JSON.stringify({
        filename: filename,
        replaceDevices: replaceDevices,
        reboot: reboot
      })
    );
  }

  getInstallerGateConfigurations(): Observable<any> {
    return this.http.get<any>(`${this.apiFmcuConfigurationUrl}/installer-gate-configurations`);
  }

  getVersion(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/version`);
  }

  finishFirstSetupSteps(objectId: string, isNeedRestart: boolean): Observable<any> {
    return this.http.put(
      `${this.apiFmcuConfigurationUrl}/finish_first_setup_steps`,
      JSON.stringify({
        objectId: objectId,
        isNeedRestart: isNeedRestart
      })
    );
  }
  gateSingleOpenEntry(): Observable<any> {
    return this.http.post(`${this.apiUrl}/normal-open-entry`, {});
  }

  gateSingleOpenExit(): Observable<any> {
    return this.http.post(`${this.apiUrl}/normal-open-exit`, {});
  }

  gateModeEmergency(): Observable<any> {
    return this.http.post(`${this.apiUrl}/gate-mode-emergency`, {});
  }

  gateModeEmergencyCancel(): Observable<any> {
    return this.http.post(`${this.apiUrl}/gate-mode-emergency-cancel`, {});
  }

  uploadUpdateSoftware(fileName: string, base64String: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/update/update-software`,
      JSON.stringify({
        fileName: fileName,
        binaryString: base64String
      }));
  }

  updateSoftwareGetStatus(): Observable<any> {
    return this.http.get(`${this.apiUrl}/update/update-software/status`, {});
  }

  private get apiConfigurationUrl(): string {
    return `${baseHttpUrl}/api/configuration`;
  }

  private get apiSchemaUrl(): string {
    return `${baseHttpUrl}/api/schema`;
  }

  private get apiFmcuConfigurationUrl(): string {
    return `${baseHttpUrl}/api/fmcu-configuration`;
  }

  getTranslations(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/translations`);
  }

  setSystemTime(time: Date): Observable<any>{
    return this.http.post(`${this.apiUrl}/system-time`, {value: time});
  }

  getConfigurationsConfig(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/configurations/configurations-config`);
  }

  updateConfigConfiguration(curentName: string, newName: string, newDisplayName: string): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/configurations/`,
      JSON.stringify({
        current_name: curentName,
        new_name: newName,
        new_display_name: newDisplayName,
      }));
  }

  copyConfigConfiguration(sourceName: string, newName: string, newDisplayName: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/configurations/`,
      JSON.stringify({
        source_name: sourceName,
        new_name: newName,
        new_display_name: newDisplayName,
      }));
  }

  deleteConfigConfiguration(name: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/configurations/${name}`);
  }

  applyConfigConfiguration(name: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/configurations/apply-configuration`,
      JSON.stringify({name}));
  }

  weacDoTeaching(teachValue: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/weac/do-teaching`,
      JSON.stringify({ teachValue: teachValue })
    );
  }

  weacEndTeaching(): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/weac/end-teaching`, {}
    );
  }
}
