import { Component, OnInit, OnDestroy } from '@angular/core';
import { noConflict } from 'lodash';
import { TimeoutError } from 'rxjs';
import { OpenVPNStatus } from 'src/app/models/status/openvpn-status';
import { AppStateService } from 'src/app/services/app-state.service';
import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';

@Component({
  selector: 'app-openvpn',
  templateUrl: './openvpn.component.html',
  styleUrls: ['./openvpn.component.css']
})
export class OpenVPNComponent implements OnInit, OnDestroy {
  private backendApi: IBackendApi;
  uploadStatus: String;
  commandStatus: String;
  status: OpenVPNStatus;
  intervalId: NodeJS.Timeout;

  constructor(
    private backendApiService: BackendApiService,
    public appStateService: AppStateService,
  ) {
    this.backendApi = backendApiService.backendApi;
  }

  ngOnInit() {
    this.intervalId = setInterval(() => this.updateStatus(), 1000);
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateStatus() {
    this.backendApi.openvpnGetStatus().subscribe((x) => {
      if (!this.status) {
        this.status = new OpenVPNStatus();
      }

      this.status.updateFromJson(x.openvpn);
    });
  }

  start() {
    this.backendApi.openvpnStart().subscribe(
      () => {
        this.commandStatus = '';
      },
      () => this.commandStatus = 'Start failed'
    );
  }

  stop() {
    this.backendApi.openvpnStop().subscribe(
      () => {
        this.commandStatus = '';
      },
      () => this.commandStatus = 'Stop failed'
    );
  }

  getUploadHandler() {
    return (file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        this.backendApi
          .openvpnUploadKey(file[0].name, reader.result as string)
          .subscribe(
              () => {
                this.uploadStatus = '';
              },
            e => {
              this.uploadStatus = 'Upload failed';
            }
          );
      };
      reader.readAsDataURL(file[0]);
    };
  }

  get canUpload() {
    if (!this.status) {
      return false;
    }

    return true;
  }

  get isActive() {
    if (!this.status) {
      return false;
    }

    return this.status.isActive;
  }

  get isEnabled() {
    if (!this.status) {
      return false;
    }

    return this.status.isEnabled;
  }

  get files(): string {
    if (!this.status || !this.status.files || !this.status.files.length) {
      return null;
    }

    return this.status.files.join('\n');
  }
}
