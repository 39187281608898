import { v4 as uuid } from 'uuid';

export class ConfigurationInfo {
  id = uuid();
  name: string;
  key = '';
  configuration: any;
  configurationOld: any;
  dirtyMessages: string[] = [];
  dirtyMessages2: string[] = [];
  invalidMessages: string[] = [];
  isRestartRequired = false;

  constructor(
    key: string,
    configuration: any,
    configurationOld: any,
    name?: string
  ) {
    this.name = name == null ? key : name;
    this.key = key;
    this.configuration = configuration;
    this.configurationOld = configurationOld;
  }

  updateFromOther(other: ConfigurationInfo) {
    if (other == null) {
      console.error('other is null');
      return;
    }
    this.name = other.name;
    this.key = other.key;
    this.updateDictionary(this.configuration, other.configuration);
    this.updateDictionary(this.configurationOld, other.configurationOld);
    this.dirtyMessages = other.dirtyMessages;
    this.dirtyMessages2 = other.dirtyMessages2;
    this.invalidMessages = other.invalidMessages;
    this.isRestartRequired = other.isRestartRequired;
  }

  updateDictionary(d1: any, d2: any) {
    Object.keys(d1).forEach(x => delete d1[x]);
    Object.keys(d2).forEach(x => (d1[x] = d2[x]));
  }
}
