import { MainPageComponent } from './pages/home-page/home-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CookieModule } from 'ngx-cookie';
import { D3Service } from 'd3-ng2-service';
import { LogService } from './services/log/log.service';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { LanguageService } from './services/language.service';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { BackendApiService } from './services/backend-api/backend-api.service';
import { BackendEventsService } from './services/backend-events/backend-events.service';
import { BackendApiHttpService } from './services/backend-api/backend-api-http.service';
import { BackendEventsWebsocketService } from './services/backend-events/backend-events-websocket';
import { NoConnectionComponent } from './components/no-connection/no-connection.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { StatusPageComponent } from './pages/status-page/status-page.component';
import { GateComponent } from './components/gate/gate.component';
import { GateControlsGeneralComponent } from './components/gate-controls/gate-controls-general/gate-controls-general.component';
import { GateControlsSideComponent } from './components/gate-controls/gate-controls-side/gate-controls-side.component';
import { GateControlsGpioComponent } from './components/gate-controls/gate-controls-gpio/gate-controls-gpio.component';
import { AppStateService } from './services/app-state.service';
import { SwitchComponent } from './components/common/switch/switch.component';
import { GateControlsUsbHubComponent } from './components/gate-controls/gate-controls-usb-hub/gate-controls-usb-hub.component';
import { GateInfoStatusComponent } from './components/gate-info/gate-info-status/gate-info-status.component';
import { GateInfoStatisticsComponent } from './components/gate-info/gate-info-statistics/gate-info-statistics.component';
import { GateInfoMachinesComponent } from './components/gate-info/gate-info-machines/gate-info-machines.component';
import { StatusDevicesComponent } from './components/status/status-devices/status-devices.component';
import { StatusDisplaysComponent } from './components/status/status-displays/status-displays.component';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { TOKEN } from './services/auth.service';
import { routing } from './app.routing';
import { DownloadComponent } from './components/download/download.component';
import { GateInfoSystemComponent } from './components/gate-info/gate-info-system/gate-info-system.component';
import { StatusDispatcherComponent } from './components/status/status-dispatcher/status-dispatcher.component';
import { FilesPageComponent } from './pages/files-page/files-page.component';
import { SharedModule, HttpLoaderFactory } from './modules/shared/shared.module';
import { NavigationButtonComponent } from './components/navigation-button/navigation-button.component';
import { StatusServerComponent } from './components/status/status-server/status-server.component';
import { Cookie } from './models/cookie';
import { CardsPageComponent } from './pages/cards-page/cards-page.component';
import { CardsComponent } from './components/cards/cards/cards.component';
import { StatisticsPageComponent } from './pages/statistics-page/statistics-page.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FmcuCoreNgModule } from 'fmcu-core-ng';
import { StatisticsService } from './services/statistics.service';
import { CameraPageComponent } from './pages/camera-page/camera-page.component';
import { GateUnitComponent } from './components/gate-unit/gate-unit.component';
import { RandomWinnerPageComponent } from './pages/random-winner-page/random-winner-page.component';
import { RandomWinnerTrackerComponent } from './components/random-winner-tracker/random-winner-tracker.component';
import { RandomWinnerHistoryComponent } from './components/random-winner-history/random-winner-history.component';
import { GateInfoEventsComponent } from './components/gate-info/gate-info-events/gate-info-events.component';
import { NotifierModule } from 'angular-notifier';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RolesService } from './services/backend-api/roles.service';
import { UnauthorisedPageComponent } from './pages/unauthorised-page/unauthorised-page.component';
import { LoadingPageComponent } from './pages/loading-page/loading-page.component';
import { GateInfoAlarmsComponent } from './components/gate-info/gate-info-alarms/gate-info-alarm.component';
import { FmcuConfigurationPageComponent } from './pages/fmcu-configuration-page/fmcu-configuration-page.component';
import { environment } from '../environments/environment';
import { GateCompoundViewComponent } from './components/gate-compound-view/gate-compound-view.component';
import { FmcuSetupPageComponent } from './pages/fmcu-setup-page/fmcu-setup-page.component';
import { FileIoService } from './modules/weac/services/file-io-service';
import { FirstSetupStepsStateService } from './services/first-setup-steps-state.service';
import { LocalizedDatePipeModule } from 'projects/fmcu-core-ng/src/lib/pipes/localized-date-pipe.module';


registerLocaleData(localeDe);

export function getToken() {
  return Cookie.get(TOKEN);
}

export const whitelistedDomains = [new RegExp('[sS]*')] as RegExp[];
export function jwtOptionsFactory() {
  return { tokenGetter: getToken, whitelistedDomains: whitelistedDomains };
}

const configuration = {
  environment: environment,
  tokenCookieName: 'jwt',
  allowSimpleWebsocket: false,
  baseHref: null,
};
@NgModule({
  declarations: [
    AppComponent,
    GateComponent,
    GateControlsSideComponent,
    GateControlsGpioComponent,
    GateControlsGeneralComponent,
    PageHeaderComponent,
    PageFooterComponent,
    NoConnectionComponent,
    LanguageSwitchComponent,
    DashboardPageComponent,
    StatusPageComponent,
    SwitchComponent,
    GateControlsUsbHubComponent,
    GateInfoStatusComponent,
    GateInfoStatisticsComponent,
    GateInfoMachinesComponent,
    GateInfoSystemComponent,
    GateInfoAlarmsComponent,
    StatusDevicesComponent,
    StatusDisplaysComponent,
    StatusDispatcherComponent,
    StatusServerComponent,
    LoginPageComponent,
    DownloadComponent,
    FilesPageComponent,
    NavigationButtonComponent,
    CardsComponent,
    CardsPageComponent,
    StatisticsPageComponent,
    CameraPageComponent,
    GateUnitComponent,
    RandomWinnerPageComponent,
    RandomWinnerTrackerComponent,
    RandomWinnerHistoryComponent,
    GateInfoEventsComponent,
    UnauthorisedPageComponent,
    LoadingPageComponent,
    FmcuConfigurationPageComponent,
    MainPageComponent,
    GateCompoundViewComponent,
    FmcuSetupPageComponent,
  ],
  imports: [
    routing,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CookieModule.forRoot(),
    UiSwitchModule,
    NgbModule,
    SharedModule,
    NgxDatatableModule,
    NotifierModule.withConfig({
      'behaviour': {
        onClick: 'hide',
      },
    }),
    BrowserAnimationsModule,
    FmcuCoreNgModule.forRoot(configuration),
    LocalizedDatePipeModule
  ],
  providers: [
    D3Service,
    LanguageService,
    LogService,
    AppStateService,
    AuthService,
    AuthGuardService,
    AppStateService,
    BackendApiService,
    BackendApiHttpService,
    BackendEventsService,
    BackendEventsWebsocketService,
    StatisticsService,
    RolesService,
    FileIoService,
    FirstSetupStepsStateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
