import * as _ from 'lodash';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Translator } from '../../models/translator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { MetaClass, MetaField } from '../../models/meta';
import { ConfigurationInfo } from '../../models/configuration-info';
import { Utils } from '../../models/utils';
import { IBackendApi } from '../../../../services/backend-api/backend-api.interface';
import { FormViewSettings } from '../../models/form-view-settings';

const getFormlyType = (type: string): string => {
  switch (type) {
    case 'boolean':
      return 'switch';
    case 'list':
      return 'select';
    case 'multicheckbox':
      return 'multicheckbox';
    case 'slider':
      return 'slider';
    default:
      return 'input';
  }
};

const getFormlySubtype = (type: string): string => {
  switch (type) {
    case 'boolean':
    case 'list':
      return null;
    case 'multicheckbox':
      return null;
    case 'int':
      return 'number';
    default:
      return type;
  }
};

interface FormlyFieldConfigExt extends FormlyFieldConfig {
  categoryId: string;
}

@Component({
  selector: 'app-configuration-form',
  templateUrl: 'configuration-form.component.html',
  styleUrls: ['configuration-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationFormComponent extends Translator
  implements OnChanges, OnDestroy {
  @Input() schema: MetaClass;
  @Input() configurationInfo: ConfigurationInfo;
  @Input() options: FormlyFormOptions = {};
  @Output() configurationChange = new EventEmitter<any>();
  form = new FormGroup({});
  fields: FormlyFieldConfigExt[] = [];
  subscription: Subscription;
  configurationInfoOld: ConfigurationInfo;
  @Input() formViewSettings: FormViewSettings = null;
  private _dirty = false;
  private _invalidMessages: string[] = [];

  constructor(private translateService: TranslateService) {
    super(translateService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initialize();
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  protected translate() {
    this.translateObj(this.fields);
  }

  private initialize() {
    if (!this.configurationInfo) {
      return;
    }

    const configuration = this.configurationInfo.configuration;
    this.configurationInfoOld = Utils.clone(
      this.configurationInfo.configurationOld
    );

    const keysToShow = _.get(this.formViewSettings, 'keysToShow');
    const hideField = (key => keysToShow && keysToShow.length && !keysToShow.some(x => x === key));

    this.form.controls = {};
    const fields_ = _.get(this.schema, 'fields', []) as MetaField[];
    this.fields = fields_
      .filter(x => x.hidden == null || !x.hidden)
      .map(x => {
        const templateOptions = {};
        const type = getFormlySubtype(x.type);
        if (type) {
          templateOptions['type'] = type;
        }

        Object.keys(x)
          .filter(
            k =>
              ![
                'type',
                'value',
                'name',
                'pattern',
                'className',
                'invalid_value_message'
              ].includes(k)
          )
          .forEach(k => (templateOptions[k] = x[k]));

        if (x.pattern != null) {
          const e = eval;
          templateOptions['pattern'] = e(x.pattern);
        }

        const validationMessage =
          x.invalid_value_message == null
            ? 'Invalid value'
            : x.invalid_value_message;
        const validation = {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              this.translateService.instant(validationMessage)
          },
          show: true,
        };

        if (this.options && this.options['formState']) {
          x.expression_properties['templateOptions.disabled'] = 'formState.disabled';
        }

        const r = {
          key: x.name,
          type: getFormlyType(x.type),
          templateOptions: templateOptions,
          expressionProperties: x.expression_properties,
          validation: validation,
          categoryId: x.category_id,
        };

        if (x.className) {
          r['className'] = x.className;
        }

        if (hideField(x.name)) {
          r['hideExpression'] = true;
        }
        return r;
      });

    if (!this.fields.length) {
      return;
    }

    const schema = this.schema;
    const fields = schema.fields;
    // See #9342
    // Object.keys(configuration)
    // .filter(x => fields.find(y => y.name === x) == null && (schema.schemaless_field_names.find(y => y === x) == null))
    // .forEach(x => delete configuration[x]);

    this.fields.forEach(x => {
      const key = x.key;
      if (!(key in configuration)) {
        const field = fields.find(y => y.name === key);
        if (field != null) {
          configuration[key] = field.value;
        }
      }
    });

    this.translate();
    if (this.subscription != null) {
      return;
    }

    this.subscription = this.form.valueChanges.subscribe(x =>
      this.updateState(x)
    );
    this.updateState('Init');
  }

  private updateState(x: any) {
    let isRestartRequired = false;
    const f = () => (isRestartRequired = true);
    this.configurationInfo.dirtyMessages = this.getControlNames('dirty', f);
    this._dirty = this.configurationInfo.dirtyMessages.length > 0;
    this.configurationInfo.invalidMessages = this.getControlNames('invalid');
    this._invalidMessages = this.configurationInfo.invalidMessages;
    this.configurationInfo.isRestartRequired = isRestartRequired;
    this.configurationChange.next(x);
  }

  getControlNames(
    attributeName: string,
    setRestartRequired: () => void = null
  ): string[] {
    const names = [];
    const controls = this.form.controls;
    const push = (key: string) => {
      const field = this.schema.fields.find(x => x.name === key);
      const label = field == null ? key : field.label;
      names.push(`<${this.configurationInfo.name}>. <${label}>`);
    };

    const configuration = this.configurationInfo.configuration;

    Object.keys(controls).forEach(key => {
      const control = controls[key];
      const field = this.schema.fields.find(y => y.name === key);

      const ensureDefinedValue = (value: any, checkForNull = false): any => {
        const isUndefined = value === undefined || (checkForNull && value === null);
        return isUndefined && field != null ? field.value : value;
      };

      const getNormalizedValue = (value: any): any => {
        if (value != null) {
          return value;
        }

        if (field.type === 'boolean') {
          return false;
        } else if (field.type === 'string') {
          return '';
        }
        return value;
      };

      // FIX: dirty is not set to faslse
      const isDirty = (): boolean => {
        const oldValue = ensureDefinedValue(this.configurationInfoOld[key]);

        const normalizedOldValue = getNormalizedValue(oldValue);
        if (normalizedOldValue !== oldValue) {
          this.configurationInfoOld[key] = normalizedOldValue;
        }

        const normalizedNewValue = getNormalizedValue(configuration[key]);
        const dirty = attributeName === 'dirty' && !_.isEqual(normalizedOldValue, normalizedNewValue);
        return dirty;
      };

      const isInvalid = (): boolean => {
        if (attributeName !== 'invalid') {
          return false;
        }
        return (control.value === null && field.required) || (control.value !== null && control[attributeName]);
      };

      if (isDirty() || isInvalid()) {
        push(key);
        const restartRequired =
          field == null ? true : field.restart_required;
        if (restartRequired && setRestartRequired != null) {
          setRestartRequired();
        }
      }
    });
    return names;
  }

  get hideCategories(): boolean {
    return this.formViewSettings && this.formViewSettings.hideCategories;
  }

  getFields(categoryId: string = null): any {
    if (this.hideCategories) {
      return this.fields;
    }

    return this.fields.filter(x => x.categoryId === categoryId);
  }

  get dirty(): boolean {
    return this._dirty;
  }

  get invalidMessages(): string[] {
    return this._invalidMessages;
  }
}
