import { Component, OnInit, Input } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';

@Component({
  selector: 'app-installation-executable-device-type',
  templateUrl: './installation-executable-device-type.component.html',
  styleUrls: ['./installation-executable-device-type.component.css']
})
export class InstallationExecutableDeviceTypeComponent implements OnInit {
  private backendApi: IBackendApi;

  installerGateConfigurations: any;
  installerGateType: string = null;
  installerConfigurationList: any[];
  installerGateTypeTags = {};

  currentConfigurationType: string = null;
  currentGateType: string = null;

  @Input()
  checkCurrentConfigurationType = false;

  _selectedTags = [];

  installerSettingsFileName: string = null;
  installerSettingsDescription: string = null;
  installerGateConfigurationId: string = null;

  constructor(
    private backendApiService: BackendApiService,
  ) {
    this.backendApi = backendApiService.backendApi;
  }

  ngOnInit(): void {
    this.loadInstallerConfiguration();
  }

  set selectedTags(value) {
    this._selectedTags = value;
    this.updateInstallerConfigurationList();
  }

  get selectedTags() {
    return this._selectedTags;
  }

  private loadInstallerConfiguration() {
    this.backendApi.getInstallerGateConfigurations().subscribe(
      x => {
        if (!x || !x.installerGateConfigurations) {
          return;
        }

        this.installerGateConfigurations = x.installerGateConfigurations;
        this.currentGateType = x.currentGateType;
        this.currentConfigurationType = x.currentConfigurationType;

        if (this.installerGateConfigurations && this.installerGateConfigurations.length > 0) {
          let currentGateTypeExistInGateConfigurations = false;
          if (this.checkCurrentConfigurationType) {
            for (const installerGateConfiguration of this.installerGateConfigurations) {
              if (installerGateConfiguration.gate_device_class === this.currentGateType) {
                currentGateTypeExistInGateConfigurations = true;
                break;
              }
            }
          }

          this.installerGateType = currentGateTypeExistInGateConfigurations
          ? this.currentGateType : this.installerGateConfigurations[0].gate_device_class;
          this.initInstallerGateTypeTags();
          this.onChangeInstallerGateType();
        }
      });
  }

  initInstallerGateTypeTags() {
    if (!this.installerGateConfigurations) {
      return;
    }

    for (const installerGateConfiguration of this.installerGateConfigurations) {
      const gateType = installerGateConfiguration.gate_device_class;
      if (!this.initInstallerGateTypeTags || this.installerGateTypeTags && !(gateType in this.installerGateTypeTags)) {
        this.installerGateTypeTags[gateType] = [];
      }

      for (const configurationList of installerGateConfiguration.configuration_list) {
        if (!configurationList.tags || configurationList.tags.length === 0) {
          continue;
        }

        for (const tag of configurationList.tags) {
          if (this.installerGateTypeTags[gateType].indexOf(tag) !== -1) {
            continue;
          }
          this.installerGateTypeTags[gateType].push(tag);
        }
      }
    }

    if (this.installerGateTypeTags) {
      Object.entries(this.installerGateTypeTags).forEach(
        ([gateType, tags]) => {
          this.installerGateTypeTags[gateType].sort((a, b) => a > b ? 1 : -1);
        }
      );
    }

  }

  onChangeInstallerGateType() {
    this._selectedTags = [];
    this.updateInstallerConfigurationList();
    this.resetInstallerSettings();
    if (this.installerConfigurationList && this.installerConfigurationList.length > 0) {
      for (const installerConfigurationList of this.installerConfigurationList) {
        if (this.checkCurrentConfigurationType &&
          this.currentConfigurationType
          && this.currentConfigurationType === installerConfigurationList.gate_configuration_id) {
          this.setConfigurationTypeByConfigurationList(installerConfigurationList);
          break;
        }

        if (installerConfigurationList.default) {
          this.setConfigurationTypeByConfigurationList(installerConfigurationList);
        }
      }

      this.fillDefaultInstallerSettings();
    }
  }
  resetInstallerSettings() {
    this.installerSettingsFileName = '';
    this.installerSettingsDescription = '';
    this.installerGateConfigurationId = '';
  }

  setConfigurationTypeByConfigurationList(configurationList) {
    if (!configurationList) {
      return;
    }

    if (configurationList.tags && configurationList.tags.length > 0) {
      this.selectedTags = [configurationList.tags[0]];
    }
    this.installerSettingsFileName = configurationList.settings_file_name;
    this.installerSettingsDescription = configurationList.description;
    this.installerGateConfigurationId = configurationList.gate_configuration_id;
  }

  updateInstallerConfigurationList() {
    this.resetInstallerSettings();

    if (!this.installerGateType) {
      return;
    }


    let currentGateConfiguration = null;
    for (const installerGateConfiguration of this.installerGateConfigurations) {
      const gateType = installerGateConfiguration.gate_device_class;
      if (gateType !== this.installerGateType) {
        continue;
      }
      currentGateConfiguration = installerGateConfiguration;
      break;
    }

    if (!currentGateConfiguration) {
      return;
    }

    const configurationLists = currentGateConfiguration.configuration_list;
    const filteredConfigurationListsByTags = [];
    if (this.selectedTags && this.selectedTags.length > 0 && configurationLists && configurationLists.length > 0) {
      for (const configurationList of configurationLists) {
        if (!configurationList.tags || !configurationList.tags.length) {
          continue;
        }

        const findTags = configurationList.tags.filter(x => this.selectedTags.indexOf(x) > -1);

        if (findTags && findTags.length === this.selectedTags.length) {
          filteredConfigurationListsByTags.push(configurationList);
        }
      }
      this.installerConfigurationList = filteredConfigurationListsByTags;
    } else {
      this.installerConfigurationList = configurationLists;
    }

    this.fillDefaultInstallerSettings();
  }

  fillDefaultInstallerSettings() {
    if (!this.installerConfigurationList || !this.installerConfigurationList.length) {
      return;
    }
    if (!this.installerSettingsFileName) {
      this.installerSettingsFileName = this.installerConfigurationList[0].settings_file_name;
    }
    if (!this.installerSettingsDescription) {
      this.installerSettingsDescription = this.installerConfigurationList[0].description;
    }
    if (!this.installerGateConfigurationId) {
      this.installerGateConfigurationId = this.installerConfigurationList[0].gate_configuration_id;
    }
  }

  onSelectConfiguration(installerGateConfiguration) {
    this.installerSettingsFileName = installerGateConfiguration.settings_file_name;
    this.installerSettingsDescription = installerGateConfiguration.description;
    this.installerGateConfigurationId = installerGateConfiguration.gate_configuration_id;
  }


}
