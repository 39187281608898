import { v4 as uuid } from 'uuid';
import { GateContact } from '../gate-contact';
import { GateUnitPosition } from './gate-unit-position';

export enum GateUnitFeature {
  DoorSelection,
}
export class GateUnit {
  uid = uuid();
  unit: number;
  name: string;
  version: string;
  isAlive = false;
  isSimulator: boolean;
  jsonKey: string;
  doorSpeed: number;

  gateInputContacts: GateContact[] = [];
  gateOutputContacts: GateContact[] = [];
  _gateContacts: GateContact[] = [];
  position = GateUnitPosition.Unknown;

  get gateContacts(): GateContact[] {
    return this._gateContacts;
  }

  constructor(unit: number,
    name: string = 'Unit',
    isAlive: boolean = false,
    version: string = '',
    isSimulator: boolean = true,
    gateInputContacts: GateContact[] = [],
    gateOutputContacts: GateContact[] = [],
  ) {
    this.unit = unit;
    this.name = name;
    this.isAlive = isAlive;
    this.version = version || '???';
    this.isSimulator = isSimulator;
    this.jsonKey = GateUnit.getKey(unit);
    this.gateInputContacts = gateInputContacts;
    this.gateOutputContacts = gateOutputContacts;
    this.updateGateContacts();
  }

  static getKey(unit: number) {
    return `gate_unit_${unit}`;
  }

  static getUnitKeys(): string[] {
    return Array.apply(null, { length: 4 }).map(Number.call, Number).map(x => GateUnit.getKey(x));
  }

  updateFromJson(json: any) {
    const update = (
      gateContacts: GateContact[],
      jsonContacts: any[],
      enabled: boolean) => {
      const length = gateContacts.length;
      for (let i = 0; i < jsonContacts.length; i++) {
        const x = jsonContacts[i];
        if (i < length) {
          gateContacts[i].updateFromJson(x);
        } else {
          gateContacts.push(new GateContact(x.name, x.value, x.simulatable, x.action_tag, x.set_from_remote));
        }
      }
    };

    this.name = json.name;
    this.isAlive = json.is_alive;
    this.version = json.version;
    this.isSimulator = json.is_simulator;
    this.position = json.position as GateUnitPosition;
    this.doorSpeed = json.door_speed;
    update(this.gateInputContacts, json.gate_input_contacts, true);
    update(this.gateOutputContacts, json.gate_output_contacts, false);
    this.updateGateContacts();
  }

  private updateGateContacts() {
    this._gateContacts = this.gateInputContacts.concat(this.gateOutputContacts);
  }

  isFeatureSupported(x: GateUnitFeature, minVersion: string): boolean {
    if (x === GateUnitFeature.DoorSelection) {
      const vCur =  parseInt(this.version, 10);
      const vMin = parseInt(minVersion, 10);
      return !vCur || !vMin ? false : vCur >= vMin;
    }

    return false;
  }
}
