import * as _ from 'lodash';
import { GateLight } from './gate-light';
import { GateSide, GateSides } from './gate-side';
import { BarcodeReader } from './bridge/barcode-reader';
import { FireAlarm } from './bridge/fire-alarm';
import { Gpio, GpioContact } from './bridge/gpio-contact';
import { GateModeManagerState } from './bridge/gate-mode-manager-state';
import { Bridge, IBackendStatement } from './bridge/bridge';
import { UsbHubLocationEnum, UsbHub } from './bridge/usb-hub';
import { CompositStatus } from './status/composit-status';
import { Statistics } from './statistics';
import { StatisticsMachine } from './statistics-machine';
import { DevicesStatus } from './status/devices-status';
import { DisplaysStatus } from './status/displays-status';
import { Subject } from 'rxjs/Subject';
import { FileIoTask } from '../modules/weac/models/file-io-task';
import { FileIoTaskStep } from '../modules/weac/models/file-io-task-step';
import { GateUnit } from './gate-unit/gate-unit';
import { FileDescriptor } from './file-descriptor';
import {
  DispatcherStatus,
  DispatchersStatus
} from './status/dispatcher-status';
import { ComboBoxItem } from 'fmcu-core-ng';
import { JsonConvertorUtility } from 'fmcu-core-ng';
import { ServerStatus } from './status/server-status';
import { LocalBarcodesManager } from './local-barcodes-manager';
import { Audio } from './audio';
import { AudioPlayer } from './bridge/audio-player';
import { RandomWinner } from './random-winner/random-winner';
import { LastEvents } from './last-event';
import { GateSignalsManager } from './gate-signal/gate-signals-manager';
import { LightBarController } from './light_bar/light_bar_controller';
import { IBackendApi } from '../services/backend-api/backend-api.interface';
import { LedPlayerUpdateService } from './led-player-update-service';
import { LedPlayer } from './led-player';
import { UsbDevice } from './usb-device';
import { Alarm } from './alarm';
import { Gate } from './gate';
import { Role } from './role/role';
import { AppEvent, AppEventFlag } from './app-event';
import { Dict } from './interfaces';

/* tslint:disable:no-bitwise */
export enum StateChangeFlags {
  None = 0,
  SimpleState = 1 << 0,
  DevicesStatus = 1 << 1,
  Alarms = 1 << 2,
  StaticticsVisits = 1 << 3,
  Confguration = 1 << 4,
  GateMode = 1 << 5,
}

export class AppState {
  version: string;
  settings: string;
  boardInfo: string;
  gateLight = new GateLight();
  externalLight = false;
  readers: BarcodeReader[] = [];
  gpios = new Map<GateSide, Gpio>();
  doorAnimation = '';
  fireAlarm = new FireAlarm();
  gateModeManagerState = new GateModeManagerState();
  audioPlayer = new AudioPlayer();
  usbHubs = new Map<UsbHubLocationEnum, UsbHub>();
  compositStatus = new CompositStatus();
  statistics = new Statistics();
  statisticsMachine = new StatisticsMachine();
  lastEvents = new LastEvents();
  devicesStatus = new DevicesStatus();
  displaysStatus = new DisplaysStatus();
  dispatchersStatus = new DispatchersStatus();
  activeDispatcherId: string;
  configuration: any;
  systemConfiguration: any;
  hardwareInfo: string;
  usbDevices: UsbDevice[] = [];
  schema: any;
  schemaChanged = new Subject<any>();
  fileIoPayloadChanged = new Subject<boolean>();
  fileIoTaskChange = new Subject<FileIoTask>();
  fileIoTaskStepChange = new Subject<FileIoTaskStep>();
  gateUnits: GateUnit[] = new Array();
  gateUnitChanged = new Subject();
  startTime: Date;
  downloadableFiles: FileDescriptor[];
  serverStatus = new ServerStatus();
  localBarcodesManager = new LocalBarcodesManager();
  teachingModeChange = new Subject<boolean>();
  teachingBarcodeChange = new Subject<string>();
  private _isTeachingMode = false;
  uiControlsEnabledChanged = new Subject<boolean>();
  private _isUiControlsEnabled = false;
  offButton = false;
  updateAppStateRequest = new Subject();
  ledPlayersChanged = new Subject<any>();
  randomWinner: RandomWinner;
  randomWinnerHistoryChanged = new Subject<any>();
  randomWinnerExist = false;
  gateSignalsManager = new GateSignalsManager();
  lightBarController: LightBarController;
  ledPlayerUpdateService = new LedPlayerUpdateService();
  ledPlayers: LedPlayer[] = [];
  barrierFilesChanged = new Subject<string>();
  roleReceived = new Subject<any>();
  private backendApi: IBackendApi;
  readersExist = false;
  alarms: Alarm[] = [];
  installerFilenames: string[] = [];
  gate = new Gate();
  simpleState: string;
  stateChanged = new Subject<StateChangeFlags>();
  appEventReceived = new Subject<AppEvent>();
  isLightAlarmActive = false;

  constructor(backendApi: IBackendApi) {
    GateSides.forEach(s => this.gpios.set(s, new Gpio(s)));
    UsbHub.getUsbLocations().forEach(x => this.usbHubs.set(x, new UsbHub(x)));
    this.backendApi = backendApi;
    this.lightBarController = new LightBarController(this.backendApi);
  }

  get bridgedModels(): IBackendStatement[] {
    return [
      this.fireAlarm,
      this.audioPlayer,
      this.gateModeManagerState,
      ...this.readers,
      ...this.getGpioContacts(),
      ...this.usbHubLocal.usbPorts,
      ...this.usbHubRemote.usbPorts,
      ...this.devicesStatus.items,
      ...this.dispatchersStatus.items
    ];
  }

  getGpioContacts(gateSide?: GateSide): GpioContact[] {
    const gateSides = gateSide ? [gateSide] : [GateSide.Entry, GateSide.Exit];
    let result = [];
    gateSides.forEach((x: GateSide) => {
      const gpio = this.gpios.get(x);
      result = result.concat(gpio.gpioContacts);
    });
    return result;
  }

  get usbHubLocal(): UsbHub {
    return this.usbHubs.get(UsbHubLocationEnum.Local);
  }

  get usbHubRemote(): UsbHub {
    return this.usbHubs.get(UsbHubLocationEnum.Remote);
  }

  get activeDispatcher(): DispatcherStatus {
    const activeDispatcherId = this.activeDispatcherId;
    if (activeDispatcherId == null) {
      return null;
    }

    return this.dispatchersStatus.getItem(activeDispatcherId);
  }

  get testBarcodes(): ComboBoxItem[] {
    return this.activeDispatcher == null
      ? []
      : this.activeDispatcher.testBarcodes;
  }

  get isTeachingMode(): boolean {
    return this._isTeachingMode;
  }

  set isTeachingMode(x: boolean) {
    if (x === this._isTeachingMode) {
      return;
    }

    this._isTeachingMode = x;
    this.teachingModeChange.next(x);
  }

  get uiControlsEnabled(): boolean {
    return this._isUiControlsEnabled;
  }

  set uiControlsEnabled(x: boolean) {
    if (x === this._isUiControlsEnabled) {
      return;
    }

    this._isUiControlsEnabled = x;
    this.uiControlsEnabledChanged.next(x);
  }

  updateFromJson(json: any): AppState {
    if (json == null) {
      return;
    }

    let flags = StateChangeFlags.None;

    if (json.version) {
      this.version = json.version;
    }

    if (json.settings) {
      this.settings = json.settings;
    }

    if (json.board_info) {
      this.boardInfo = json.board_info;
    }

    if ('externalLight' in json) {
      this.externalLight = json.externalLight;
    }

    if (json.gateLight) {
      this.gateLight = GateLight.fromJson(
        GateLight,
        json.gateLight,
        this.gateLight
      );
    }

    [[json.gpioEntry, GateSide.Entry], [json.gpioExit, GateSide.Exit]].forEach(
      x => {
        const dict = x[0];
        if (dict) {
          const gateSide = x[1];
          this.gpios
            .get(gateSide)
            .gpioContacts.forEach(gpioContact =>
              gpioContact.updateValueFromJson(dict[gpioContact.apiName])
            );
        }
      }
    );

    if (json.last_events) {
      this.lastEvents.updateFromJson(json.last_events);
    }

    if (json.gateModeManagerState) {
      this.gateModeManagerState.updateValueFromJson(json.gateModeManagerState);
      flags |= StateChangeFlags.GateMode;
    }

    const audioPlayer = json.audioPlayer;
    if (audioPlayer) {
      this.audioPlayer.play(new Audio(audioPlayer.pathToFile, audioPlayer.fileName, audioPlayer.volume));
    }

    this.usbHubs.forEach((usbHub: UsbHub, location: UsbHubLocationEnum) => {
      const otherUsbPorts = json[usbHub.pythonDevice];
      if (otherUsbPorts) {
        for (let i = 0; i < otherUsbPorts.length; i++) {
          const usbPort = usbHub.getUsbPort(i);
          usbPort.updateValueFromJson(otherUsbPorts[i]);
        }
      }
    });

    if ('status_items' in json) {
      this.compositStatus.updateFromJson(json.status_items);
    }

    if ('status_item' in json) {
      this.compositStatus.updateStatusItemFromJson(json.status_item);
    }

    if (json.statistics) {
      this.statistics.updateFromJson(json.statistics);
    }

    if (json.statistics_item) {
      this.statistics.updateStatisticsItemFromJson(json.statistics_item);
    }

    if (json.machines) {
      this.statisticsMachine.updateMachinesFromJson(json.machines);
    }

    if (json.machine) {
      this.statisticsMachine.updateMachineFromJson(json.machine);
    }

    if (json.devices) {
      this.devicesStatus.updateFromJson(json.devices);
    }

    if (json.device) {
      this.devicesStatus.updateItemFromJson(json.device);
      flags |= StateChangeFlags.DevicesStatus;
    }

    if (json.displays) {
      this.displaysStatus.updateFromJson(json.displays);
    }

    if ('doorAnimation' in json) {
      this.doorAnimation = json.doorAnimation;
    }

    if ('readers' in json) {
      this.readers = json.readers.map(x => new BarcodeReader(x.readerIndex, x.name));
    }

    if ('isFireAlarm' in json) {
      this.fireAlarm.updateValueFromJson(json.isFireAlarm);
    }

    if ('schema' in json) {
      this.schema = json.schema;
      this.schemaChanged.next(this.schema);
    }

    if ('configuration' in json) {
      this.configuration = json.configuration;
      flags |= StateChangeFlags.Confguration;
    }

    if ('system_configuration' in json) {
      this.systemConfiguration = json.system_configuration;
    }

    if ('file_io_task' in json) {
      this.fileIoTaskChange.next(json.file_io_task);
    }

    if ('file_io_task_step' in json) {
      const fileIoTaskStep = FileIoTaskStep.fromJson(json.file_io_task_step);
      this.fileIoTaskStepChange.next(fileIoTaskStep);
    }

    if ('file_io_payload_available' in json) {
      this.fileIoPayloadChanged.next(json.file_io_payload_available);
    }

    const gateUnits = GateUnit.getUnitKeys()
      .map(x => json[x])
      .filter(x => x != null);

    if (gateUnits.length) {
      gateUnits.forEach(x => {
        let gateUnit = this.gateUnits.find(y => y.unit === x.unit);
        if (!gateUnit) {
          const units = _.clone(this.gateUnits);
          gateUnit = new GateUnit(x.unit);
          units.push(gateUnit);
          this.gateUnits = units.sort((a, b) => a.unit - b.unit);
        }
        gateUnit.updateFromJson(x);
      });
      this.gateUnitChanged.next();
    }

    if ('start_time' in json) {
      this.startTime = new Date(json.start_time);
    }

    if ('downloadable_files' in json) {
      const downloadableFiles = [];
      json.downloadable_files.forEach(x =>
        downloadableFiles.push(FileDescriptor.fromJson(x))
      );
      this.downloadableFiles = downloadableFiles;
    }

    if ('hardware_info' in json) {
      this.hardwareInfo = json.hardware_info;
    }

    if ('usb_devices' in json) {
      this.usbDevices = json.usb_devices.map(x => new UsbDevice(x.path, x.serial));
    }

    if ('dispatchers' in json) {
      this.dispatchersStatus.updateFromJson(json.dispatchers);
    }

    if ('dispatcher' in json) {
      this.dispatchersStatus.updateFromJson([json]);
    }

    if ('active_dispatcher_id' in json) {
      this.activeDispatcherId = json.active_dispatcher_id;
    }

    if ('server' in json) {
      this.serverStatus.updateFromJson(json.server);
    }

    if ('local_barcodes' in json) {
      this.localBarcodesManager.updateFromJson(json.local_barcodes);
    }

    if ('is_teaching_mode' in json) {
      this.isTeachingMode = json.is_teaching_mode;
    }

    if ('teaching_barcode' in json) {
      this.teachingBarcodeChange.next(json.teaching_barcode);
    }

    if ('led_player' in json) {
      const player = LedPlayer.createFromJson(json.led_player);

      const playerIdx = this.ledPlayers.findIndex(x => x.name === player.name);

      if (playerIdx >= 0) {
        this.ledPlayers[playerIdx] = player;
        this.ledPlayersChanged.next(null);
      }
    }

    if ('led_players' in json) {
      this.ledPlayers = json.led_players.map(x => LedPlayer.createFromJson(x));
      this.ledPlayersChanged.next(null);
    }

    if ('alarms' in json) {
      this.alarms = json.alarms.map(x => Alarm.createFromJson(x));
      flags |= StateChangeFlags.Alarms;
    }

    if ('is_light_alarm_active' in json) {
      this.isLightAlarmActive = json.is_light_alarm_active;
    }

    if (json.random_winner) {
      this.randomWinner = JsonConvertorUtility.deserialize(json.random_winner[0], RandomWinner);
    }

    if (json.random_winner_configuration_exist) {
      this.randomWinnerExist = true;
    }

    if (json.random_winner_history_changes) {
      this.randomWinnerHistoryChanged.next(json.random_winner_history_changes);
    }

    if ('gate_signals' in json) {
      this.gateSignalsManager.loadFromJson(json.gate_signals);
    }

    if ('gate_signal' in json) {
      this.gateSignalsManager.updateFromJson(json.gate_signal);
    }

    if ('light_bar' in json) {
      this.lightBarController.updateFromJson(json.light_bar);
    }

    if ('barrier_files_changed' in json) {
      this.barrierFilesChanged.next(json.barrier_files_changed.id);
    }

    if (LedPlayerUpdateService.jsonKey in json) {
      this.ledPlayerUpdateService.updateFromJson(json[LedPlayerUpdateService.jsonKey]);
    }

    if (json['off_mode_manager'] != null) {
      this.offButton = json.off_mode_manager;
    }

    if (json.readers_configuration_exist) {
      this.readersExist = true;
    }

    if ('role' in json) {
      this.roleReceived.next(Role.createFromAny(json.role));
    }

    if ('installer_filenames' in json) {
      this.installerFilenames = json.installer_filenames;
    }

    if ('gate' in json) {
      this.gate.updateFromJson(json.gate);
    }

    if ('simple_state' in json) {
      this.simpleState = json.simple_state;
      flags |= StateChangeFlags.SimpleState;
    }

    if ('statictics_visits' in json) {
      flags |= StateChangeFlags.StaticticsVisits;
    }

    if ('configuration_config' in json) {
      this.raiseAppEvent(AppEventFlag.ConfigurationConfig, json.configuration_config);
    }

    this.stateChanged.next(flags);
  }

  raiseAppEvent(flag: AppEventFlag, data: Dict) {
    this.appEventReceived.next(new AppEvent(flag, data));
  }
}
