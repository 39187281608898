import { Component, ViewEncapsulation } from '@angular/core';
import { AppState } from '../../models/app-state';
import { BackendApiService } from '../../services/backend-api/backend-api.service';
import { IBackendApi } from '../../services/backend-api/backend-api.interface';
import { GpioContact } from '../../models/bridge/gpio-contact';
import { GateSide } from '../../models/gate-side';
import { AppStateService } from '../../services/app-state.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard-page.component.html',
  styleUrls: ['dashboard-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardPageComponent {
  constructor(
    public appStateService: AppStateService,
    private backendApiService: BackendApiService
  ) {}

  get showHeight(): boolean {
    return false;
  }

  get backendApi(): IBackendApi {
    return this.backendApiService.backendApi;
  }

  get gpioContactsEntry(): GpioContact[] {
    return this.getGpioContacts(GateSide.Entry);
  }

  get gpioContactsExit(): GpioContact[] {
    return this.getGpioContacts(GateSide.Exit);
  }

  get appState(): AppState {
    return this.appStateService.appState;
  }

  private getGpioContacts(gateSide: GateSide): GpioContact[] {
    return this.appState.getGpioContacts(gateSide);
  }

  onExec(x: string) {
    this.backendApi.exec(x);
  }
}
