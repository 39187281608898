import * as _ from 'lodash';
import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { D3Service, D3, Selection } from 'd3-ng2-service';
import * as $ from 'jquery';
import { Node } from './models/node';
import { PythonConvertor } from '../../models/python-convertor';
import { BackendApiService } from '../../services/backend-api/backend-api.service';
import { IBackendApi } from '../../services/backend-api/backend-api.interface';
import { BackendEventsService } from '../../services/backend-events/backend-events.service';
import { IBackendEvents } from '../../services/backend-events/backend-events-interface';
import { Subscription } from 'rxjs/Subscription';
import { Animator } from './models/animator';
import { GateLight } from '../../models/gate-light';
import { GateDoor, GateDoorEnum } from './models/gate-door';
import { BarcodeReaderStateEnum } from '../../models/bridge/barcode-reader';
import { GateSide } from '../../models/gate-side';
import { GpioContactEnum, Gpio } from '../../models/bridge/gpio-contact';
import { AppState } from '../../models/app-state';
import { AppStateService } from '../../services/app-state.service';
import { GateSignal } from 'src/app/models/gate-signal/gate-signal';
import { TranslateService } from '@ngx-translate/core';
import { Leg } from './models/leg';
import { LifetimeStage } from 'src/app/models/lifetime-stage';
import { LightBar } from 'src/app/models/light_bar/light_bar';
import { LedPlayer } from 'src/app/models/led-player';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GateSignalsListPopupComponent } from 'src/app/modules/shared/components/gate-signals-list-popup/gate-signals-list-popup.component';

const nodeTextShiftX = 0;
const nodeTextShiftY = 7;

class ZonesLayout {
  yEntryOuter = 303;
  yEntryInner = 208;
  yExitInner = 130;
  yExitOuter = 53;
  heightEntryOuter = 100;
  heightEntryInner = 120;
  heightExitInner = 120;
  heightExitOuter = 350;
}


@Component({
  selector: 'app-gate',
  templateUrl: 'gate.component.html',
  styleUrls: ['gate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GateComponent implements OnInit, OnDestroy, OnChanges {
  private nodeId = 0;
  private d3Svg: Selection<SVGSVGElement, any, HTMLElement, undefined>;
  private parentNativeElement: any;
  private gateSensors = [
    ['lightSensorEntry', false, 'GateSensorLocation.Entry'],
    ['lightSensorMiddle', false, 'GateSensorLocation.Middle'],
    ['lightSensorExit', false, 'GateSensorLocation.Exit']
  ];
  private backendApi: IBackendApi;
  private backendEvents: IBackendEvents;
  private ledLightEntryChangedSubscription: Subscription;
  private ledLightExitChangedSubscription: Subscription;
  private animator: Animator = new Animator();
  d3: D3;
  private nodes: { [id: number]: Node } = {};
  @Input() appState: AppState;
  @Input() gateLight: GateLight;
  @Input() doorAnimation: string;
  zonesLayout = new ZonesLayout();

  private gateDoors: GateDoor[] = [
    new GateDoor('gate_door_master', 160, 224, GateDoorEnum.Unit1),
    new GateDoor('gate_door_slave', 60, 224, GateDoorEnum.Unit2)
  ];

  private subscriptionOnLangChange: Subscription;
  private subscriptionGateSignalsCollectionChanged: Subscription;
  private subscriptionLightBarValueChanged: Subscription;
  private subscriptionLedRibbonChanged: Subscription;
  gateSignals: GateSignal[] = [];

  constructor(
    private element: ElementRef,
    private d3Service: D3Service,
    private appStateService: AppStateService,
    private backendApiService: BackendApiService,
    private backendEventsService: BackendEventsService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.d3 = d3Service.getD3();
    this.parentNativeElement = element.nativeElement;
    this.backendApi = backendApiService.backendApi;
    this.backendEvents = this.backendEventsService.backendEvents;
    this.subscriptionOnLangChange = translateService.onLangChange.subscribe(() => this.onLangChange());
  }

  static getArray(value: boolean, size: number): boolean[] {
    const a = new Array<boolean>(size);
    for (let i = 0; i < size; i++) {
      a[i] = value;
    }
    return a;
  }

  ngOnInit() {
    const d3 = this.d3;
    let d3ParentElement: Selection<any, any, any, any>;

    if (this.parentNativeElement !== null) {
      d3ParentElement = d3.select(this.parentNativeElement);
      this.d3Svg = d3ParentElement.select<SVGSVGElement>('.svg');

      this.gateDoors.forEach((x: GateDoor) => {
        const scope = this;
        this.d3Svg
          .append('rect')
          .attr('id', x.id)
          .attr('style', GateLight.getDefaultStyleString())
          .attr('x', x.x)
          .attr('y', x.y)
          .attr('width', 80)
          .attr('height', 10)
          .attr('rx', 3)
          .attr('ry', 3)
          .on('click', function () {
            scope.backendApi.exec('root.gate.sim_door_alarm()');
          });
      });

      this.doorHinge(52, 229, 0, 180);
      this.doorHinge(248, 229, 180, 360);

      this.d3Svg.on('click', () => this.onClick(d3));
      this.updateDoorsLight(this.gateLight);
      this.animator.updateDoorAnimation(this.doorAnimation);

      this.ledLightEntryChangedSubscription = this.appState.ledPlayersChanged.subscribe(
        x => this.drawLedPlayers()
      );

      this.drawLedPlayers();

      this.subscriptionGateSignalsCollectionChanged =
        this.appState.gateSignalsManager.collectionChanged.subscribe(() => this.drawGateSignals());
      this.drawGateSignals();

      this.subscriptionLightBarValueChanged = this.appState.lightBarController.valueChanged.subscribe(() => this.drawLightBar());
      this.drawLightBar();
    }
  }

  drawLedPlayers() {
    const ledPlayersEntry = this.appState.ledPlayers.filter(x => x.gateSide === GateSide.Entry);
    if (ledPlayersEntry && ledPlayersEntry.length > 0) {
      this.drawLedLight(ledPlayersEntry[0]);
    }
    const ledPlayersExit = this.appState.ledPlayers.filter(x => x.gateSide === GateSide.Exit);
    if (ledPlayersExit && ledPlayersExit.length > 0) {
      this.drawLedLight(ledPlayersExit[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gateLight != null) {
      this.updateDoorsLight(changes.gateLight.currentValue);
    }
    if (changes.doorAnimation != null) {
      this.animator.updateDoorAnimation(changes.doorAnimation.currentValue);
    }
  }

  ngOnDestroy() {
    this.subscriptionOnLangChange.unsubscribe();
    if (this.ledLightEntryChangedSubscription) {
      this.ledLightEntryChangedSubscription.unsubscribe();
      this.ledLightEntryChangedSubscription = null;
    }
    if (this.ledLightExitChangedSubscription) {
      this.ledLightExitChangedSubscription.unsubscribe();
      this.ledLightExitChangedSubscription = null;
    }
    if (this.d3Svg.empty && !this.d3Svg.empty()) {
      this.d3Svg.selectAll('*').remove();
    }
    this.subscriptionGateSignalsCollectionChanged.unsubscribe();
    this.subscriptionLightBarValueChanged.unsubscribe();
  }

  onLangChange() {
    this.drawGateSignals();
  }


  private drawGateSignals() {
    const scope = this;
    this.gateSignals = this.appState.gateSignalsManager.items;
    const d3GateSignals = this.d3Svg.select<SVGSVGElement>('#gate_signals');
    d3GateSignals.selectAll('*').remove();
    const x_col_start = 308;
    const x_col_step = 50;
    const grid_count_x = 4;
    const grid_count_y = 4;

    const max_grid_count = 16;

    let en_popup_visible = false;
    let mid_popup_visible = false;
    let ex_popup_visible = false;

    const gate_side_entry = 'Entry';
    const gate_side_exit = 'Exit';

    let x_ex = x_col_start;
    let x_mid = x_col_start;
    let x_en = x_col_start;

    let count_ex = 0;
    let count_mid = 0;
    let count_en = 0;

    let total_count_ex = 0;
    let total_count_mid = 0;
    let total_count_en = 0;

    const y_ex_start = 60;
    const y_mid_start = 200;
    const y_en_start = 340;

    let y_ex = y_ex_start;
    let y_mid = y_mid_start;
    let y_en = y_en_start;

    const camWidth = 30;
    const camHeight = 23;
    const camSpaceX = 8;
    const camSpaceY = 8;
    const camR = 2;
    const textDeltaX = 4;
    const textDeltaY = 13;

    this._WriteText(d3GateSignals, x_col_start, y_ex - 7, 'Signals Exit');
    this._WriteText(d3GateSignals, x_col_start, y_mid - 7, 'Signals');
    this._WriteText(d3GateSignals, x_col_start, y_en - 7, 'Signals Entry');

    const updateGridStart = (camera: GateSignal, width: number, height: number) => {
      if (!camera) {
        return;
      }

      if (camera.gateSide === gate_side_exit) {
        count_ex++;
        if (count_ex % grid_count_x === 0) {
          x_ex = x_col_start;
          y_ex += height + camSpaceY;
        } else {
          x_ex += width + camSpaceX;
        }
        return;
      }
      if (camera.gateSide === gate_side_entry) {
        count_en++;
        if (count_en % grid_count_x === 0) {
          x_en = x_col_start;
          y_en += height + camSpaceY;
        } else {
          x_en += width + camSpaceX;
        }
        return;
      }
      count_mid++;
      if (count_mid % grid_count_x === 0) {
        x_mid = x_col_start;
        y_mid += height + camSpaceY;
      } else {
        x_mid += width + camSpaceX;
      }
    };

    const getGridStartX = (camera: GateSignal) => {
      return camera ? camera.gateSide === gate_side_exit ? x_ex : (camera.gateSide === gate_side_entry ? x_en : x_mid) : 0;
    };

    const getGridStartY = (camera: GateSignal) => {
      return camera ? camera.gateSide === gate_side_exit ? y_ex : (camera.gateSide === gate_side_entry ? y_en : y_mid) : 0;
    };

    this.gateSignals.forEach(c => {
      if (!c.enabled) {
        return;
      }

      if (c.gateSide === gate_side_exit) {
        total_count_ex++;
        return;
      }
      if (c.gateSide === gate_side_entry) {
        total_count_en++;
        return;
      }
      total_count_mid++;
    });

    const isNeedDrawShowSignalsPopupButton = (total_count: number, current_count: number) => {
      if (total_count > max_grid_count && current_count + 1 >= max_grid_count) {
        return true;
      }
      return false;
    };

    let gate_side_total_count = null;
    let gate_side_current_count = null;

    const fillGateSideCounts = (gateSignal: GateSignal) => {
      if (gateSignal.gateSide === gate_side_entry) {
        gate_side_total_count = total_count_en;
        gate_side_current_count = count_en;
        return;
      }
      if (gateSignal.gateSide === gate_side_exit) {
        gate_side_total_count = total_count_ex;
        gate_side_current_count = count_ex;
        return;
      }
      gate_side_total_count = total_count_mid;
      gate_side_current_count = count_mid;
    };


    this.gateSignals.forEach(c => {
      if (!c.enabled) {
        return;
      }
      const x = getGridStartX(c);
      const y = getGridStartY(c);

      gate_side_total_count = null;
      gate_side_current_count = null;
      fillGateSideCounts(c);

      if (isNeedDrawShowSignalsPopupButton(gate_side_total_count, gate_side_current_count)) {
        if (c.gateSide === gate_side_entry && en_popup_visible) {
          return;
        }
        if (c.gateSide === gate_side_exit) {
          return;
        }
        if ([gate_side_entry, gate_side_exit].indexOf(c.gateSide) === -1 && mid_popup_visible) {
          return;
        }

        this._DrawShowSignalsPopupButton(d3GateSignals, x, y, camWidth, camHeight, textDeltaX, textDeltaY, camR);

        if (c.gateSide === gate_side_entry) {
          en_popup_visible = true;
          return;
        }
        if (c.gateSide === gate_side_exit) {
          ex_popup_visible = true;
          return;
        }
        mid_popup_visible = true;
        return;
      }

      const gateSignalIndex = this.appState.gateSignalsManager.getIndexByGateSignal(c);
      this._DrawGateSignal(d3GateSignals, c, gateSignalIndex, x, y, camWidth, camHeight, textDeltaX, textDeltaY, camR);
      updateGridStart(c, camWidth, camHeight);
    });
  }

  private _WriteText(canvas: any, xPos: number, yPos: number, text: string, customClassCss?: string) {
    canvas
      .append('text')
      .attr('text-anchor', 'left')
      .attr('class', customClassCss ? customClassCss : 'gate-node-text-small')
      .attr('x', xPos)
      .attr('y', yPos)
      .text(`${this.translateService.instant(text)}`);
  }

  private _DrawGateSignal(canvas: any, camera: GateSignal, cameraIndex: number, x: number, y: number, width: number,
    height: number, textDeltaX: number, textDeltaY: number, r: number) {
    const scope = this;
    if (!canvas || !camera) {
      return;
    }
    const cls = this.getCssClassSignalGate(camera);

    const rect = canvas
      .append('rect')
      .attr('id', camera.domId)
      .attr('index', cameraIndex)
      .attr('class', cls)
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', height)
      .attr('rx', r)
      .attr('ry', r)
      .on('click', function () {
        const objectId = camera.objectId;
        scope.backendApi.setGateSignal(objectId, scope.getIncrementedCount(camera.count)).subscribe(
          () => {

          }, e => console.error(e));
      });

    rect.append('title').text(camera.name);
    if (camera.count > 0) {
      const delta = camera.count > 9 ? 17 : 21;
      this._WriteText(canvas, x + delta, y + width - 20, (camera.count).toString());
    }
    this._WriteText(canvas, x + textDeltaX, y + textDeltaY, '#' + (cameraIndex).toString(), 'gate-node-text-smaller');
  }

  private _DrawShowSignalsPopupButton(canvas: any, x: number, y: number, width: number,
    height: number, textDeltaX: number, textDeltaY: number, r: number) {
    if (!canvas) {
      return;
    }
    const scope = this;

    const rect = canvas
      .append('rect')
      .attr('class', 'signals-popup-button')
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', height)
      .attr('rx', r)
      .attr('ry', r)
      .on('click', function () {
        scope.showSignalsPopup('Signals', scope.gateSignals);
        return;
      });

    rect.append('title').text('Show Signals List');
    this._WriteText(canvas, x + textDeltaX + 5, y + textDeltaY + 2, '>>' , 'signals-popup-button-text');
  }

  showSignalsPopup(modalHeader: string, gateSignals: GateSignal[]) {
    const modal = this.modalService.open(GateSignalsListPopupComponent, {size: 'lg'});
    modal.componentInstance.modalHeader = modalHeader;
    modal.componentInstance.gateSignals = gateSignals;
    // modal.componentInstance.confirmButtonText = confirmButtonText;
    return modal;
  }

  get lightBar(): LightBar {
    return this.appState.lightBarController.lightBar;
  }

  private drawLightBar() {
    const lightBar = this.lightBar;
    if (!lightBar || lightBar.isStub) {
      return;
    }

    const d3LightBar = this.d3Svg.select<SVGSVGElement>('#light_bar');
    const parentBox = this.d3Svg.select<SVGAElement>('#gate_part_slave').node().getBBox();
    const width = 2;
    const vBorderDistance = 4;
    const hBorderDistance = 30;
    const completeHeight = parentBox.height - (2 * hBorderDistance);
    const x = parentBox.width - vBorderDistance;

    const between = 2;

    let step = 0;
    let y = parentBox.y + hBorderDistance + between / 2;

    const heightMiddle = 0;
    const heightOuter = (completeHeight - heightMiddle) / 2;
    const yBegin = y;
    const dangerEntryId = lightBar.dangerZoneSizeEntry > 0 ? lightBar.sensorsHEntry.length - lightBar.dangerZoneSizeEntry : -1;
    const dangerExitId = lightBar.dangerZoneSizeExit > 0 ? lightBar.dangerZoneSizeExit : -1;
    [
      ['exit', lightBar.sensorsHExit, heightOuter, dangerExitId],
      ['middle', lightBar.sensorsHMiddle, heightMiddle, -1],
      ['entry', lightBar.sensorsHEntry, heightOuter, dangerEntryId]
    ].forEach(z => {
      const name = z[0] as string;
      const sensors = z[1] as [];
      const h = z[2] as number;
      const dangerId = z[3] as number;
      step = h / sensors.length;
      for (let i = sensors.length - 1; i >= 0; i--) {
        const x2 = i === dangerId ? x - 1 : x;
        const w = i === dangerId ? width + 2 : width;
        const xx = name === 'middle' ? x - 2 : x2;
        const value = sensors[i];
        const id = this.buildLightBarHSensorId(name, i);
        const sensor = this.getElementById(id);
        const cls = value ? 'externalLightOn' : 'externalLightOff';
        if (sensor) {
          sensor.setAttribute('class', cls);
        } else {
          d3LightBar
            .append('rect')
            .attr('id', id)
            .attr('class', cls)
            .attr('x', xx)
            .attr('y', y)
            .attr('width', w)
            .attr('height', step - between);
        }
        y += step;
      }
    });

    const yEnd = y;
    const f = (isEntry: boolean, on: boolean) => {
      const id = this.buildLightBarVSensorId(isEntry);
      let vSensor = this.getElementById(id);
      const cls = on ? 'externalLightOn' : 'externalLightOff';
      if (!vSensor) {
        const h = 10;
        const w = 4;
        const _x = x - 1;
        const vDistance = 9;
        const vTop = parentBox.y + vDistance;
        const _y = isEntry ? yEnd : yBegin - h - 2;
        d3LightBar
          .append('rect')
          .attr('id', id)
          .attr('class', cls)
          .attr('x', _x)
          .attr('y', _y)
          .attr('width', w)
          .attr('height', h);
        vSensor = this.getElementById(id);
      }
      vSensor.setAttribute('class', cls);
    };

    [
      [true, lightBar.maxVEntryIndex],
      [false, lightBar.maxVExitIndex]
    ].forEach(c => f(
      c[0] as boolean,
      c[1] as number > 0)
    );
  }

  getLightBarVMaxSensorNo(isEntry: boolean): string {
    const lightBar = this.lightBar;
    const value = isEntry ? lightBar.maxVEntryIndex : lightBar.maxVExitIndex;
    return value == null ? '' : (value + 1).toString();
  }

  private updateGateSignalFromGate(x: GateSignal) {
    const el = document.getElementById(x.domId);
    el.removeAttribute('class');
    el.classList.add(this.getCssClassSignalGate(x));
  }

  getCssClassSignalGate(x: GateSignal): string {
    if (x && x.enabled) {
      return x.count ? 'gateZoneOn' : 'gateZoneOff';
    }

    return 'gateZoneDisabled';
  }

  drawLedLight(ledLight: LedPlayer) {
    if (ledLight == null || ledLight.gateSide === GateSide.NotSet) {
      return;
    }
    const startPosX = 250;
    const startPosY = ledLight.gateSide === GateSide.Entry ? 410 : 0;
    const size = 4;
    const dist = 2;
    const pixels = ledLight.pixels;
    const width = ledLight.width;

    const groupId = ledLight.gateSide === GateSide.Entry ? 'led-entry' : 'led-exit';
    this.d3Svg.select(`#${groupId}`).remove();
    const d3svgGroupSelection = this.d3Svg.append('g').attr('id', groupId);

    for (let i = 0; i < ledLight.height; i++) {
      const y = startPosY + i * (size + dist);
      for (let j = 0; j < width; j++) {
        const pos = i * width + j;
        const p = pixels[pos];
        const style = `fill: ${p}`;
        const x = startPosX + j * (size + dist);
        const id = `ll-${i}-${j}`;

        const el = d3svgGroupSelection.select(id);
        if (el.size() && + el.attr('y') === y) {
          el.attr('style', style);
          continue;
        }

        d3svgGroupSelection
          .append('rect')
          .attr('id', id)
          .attr('style', style)
          .attr('x', x)
          .attr('y', y)
          .attr('width', size)
          .attr('height', size)
          .attr('rx', 0)
          .attr('ry', 0);
      }
    }
  }

  getCssClassExternalLight(x: Map<GateSide, Gpio>): string {
    const onContacts = Array.from(x.values()).filter((y: Gpio) => y.getGpioContact(GpioContactEnum.ExtenalLight).value);
    return onContacts.length ? 'externalLightOn' : 'externalLightOff';
  }

  getCssClassGateLightSensor(x: boolean): string {
    return x ? 'sensorOn' : 'sensorOff';
  }

  getCssClassGateLightSensorEntry(): string {
    const isGateLightSensorEntryOn = this.isLightSensorOn(1, 'E2');
    return this.getCssClassGateLightSensor(isGateLightSensorEntryOn);
  }

  getCssClassGateLightSensorMiddle(): string {
    const isGateLightSensorMiddleOn = this.isLightSensorOn(1, 'E1');
    return this.getCssClassGateLightSensor(isGateLightSensorMiddleOn);
  }

  getCssClassGateLightSensorExit(): string {
    const isGateLightSensorExitOn = this.isLightSensorOn(2, 'E5');
    return this.getCssClassGateLightSensor(isGateLightSensorExitOn);
  }

  isLightSensorOn(unit: number, contact_name: string): boolean {
    const gateUnits = this.appState.gateUnits;
    let isLightSensorOn = false;

    if (!gateUnits || gateUnits.length === 0 || !unit || !contact_name) {
      return isLightSensorOn;
    }

    let contactFind = false;
    for (let i = 0; i < gateUnits.length; i++) {
      const gateUnit = gateUnits[i];
      if (gateUnit.unit === unit && gateUnit.gateContacts && gateUnit.gateContacts.length) {
        for (let j = 0; j < gateUnit.gateContacts.length; j++) {
          const gateUnitContact = gateUnit.gateContacts[j];
          if (gateUnitContact.name === contact_name) {
            contactFind = true;
            if (gateUnitContact.value === true) {
              isLightSensorOn = true;
            }
            break;
          }
        }
        if (contactFind) {
          break;
        }
      }
    }

    return isLightSensorOn;
  }

  getCssClassBarcodeReader(x: BarcodeReaderStateEnum): string {
    switch (x) {
      case BarcodeReaderStateEnum.Idle:
        return 'barcodeReaderIdle';
      case BarcodeReaderStateEnum.Reading:
        return 'barcodeReaderReading';
      default:
        return 'barcodeReaderOff';
    }
  }

  getCssClassGpio(x: boolean, cssClass: string): string {
    return x ? cssClass : 'gpioOff';
  }

  getCssClassGateSignalText(enabled: boolean) {
    if (enabled) {
      return 'gateText';
    }

    return 'gateTextDisabled';
  }

  private queueSize(x: number): string {
    return x ? x.toString() : '';
  }

  get queueEntrySize(): string {
    return this.queueSize(this.appState.statistics.queueEntrySize);
  }

  get queueExitSize(): string {
    return this.queueSize(this.appState.statistics.queueExitSize);
  }

  get gateSide(): any {
    return GateSide;
  }

  get gpioContactEnum(): any {
    return GpioContactEnum;
  }

  updateNodeState(node: Node) {
    this.updateNodesEnvironment();
  }

  canMoveNode(x, y): boolean {
    const rectSlave = this.getBBox('gate_part_slave');
    const rectMaster = this.getBBox('gate_part_master');
    const height = +this.d3Svg.node().getAttribute('height');
    const top = 0;
    const bottom = height;
    const left = rectSlave.x + rectSlave.width + this.nodeRadius;
    const right = rectMaster.x - this.nodeRadius;
    const inRectGate = x > left && x < right && y > top && y < bottom;

    return inRectGate;
  }

  canCreateNode(x, y): boolean {
    if (['gate_door_master', 'gate_door_slave'].map(name => this.isInsideRect(x, y, this.getBBox(name))).some(b => b)) {
      return false;
    }
    return this.canMoveNode(x, y);
  }

  getElementById(elementId: string | number): SVGSVGElement {
    const selector = `#${elementId}`;
    return this.d3Svg.select<SVGSVGElement>(selector).node();
  }

  buildLabelId(id: string | number): string {
    return `label-${id}`;
  }

  private buildLightBarHSensorId(name, id: string | number): string {
    return `lb-sensor-h-${name}-${id}`;
  }

  private buildLightBarVSensorId(isEntry): string {
    return `lb-sensor-v-${isEntry ? 'entry' : 'exit'}`;
  }

  private doorHingeArc(startAngle: number, endAngle: number) {
    const g = Math.PI / 180;
    return this.d3
      .arc()
      .innerRadius(0)
      .outerRadius(16)
      .startAngle(startAngle * g)
      .endAngle(endAngle * g);
  }

  private doorHinge(
    x: number,
    y: number,
    startAngle: number,
    endAngle: number
  ) {
    this.d3Svg
      .append('path')
      .attr('d', this.doorHingeArc(startAngle, endAngle))
      .attr('class', 'gateBody')
      .attr('transform', 'translate(' + x.toString() + ', ' + y + ')');
  }

  private getBBox(elementId: string): SVGRect {
    const element = this.getElementById(elementId);
    return element == null ? null : element.getBBox();
  }

  private updateNodesEnvironment() {
    this.updateGateSensors();
    this.updateLightBar();
  }

  private updateGateSensors() {
    const gateSensors = this.gateSensors;
    for (let i = 0; i < Object.keys(gateSensors).length; i++) {
      const sensor = gateSensors[i];
      const elementId = sensor[0];
      const oldValue = sensor[1];
      const simulated_object_id = this.getSensorTiggeredId(elementId);
      const newValue = simulated_object_id != null;
      if (newValue === oldValue) {
        continue;
      }

      sensor[1] = newValue;
      const gate_sensor_location_str = sensor[2];
      let params = `'${gate_sensor_location_str}'`;
      params += `, ${PythonConvertor.toBoolean(newValue)}`;
      params += `, ${simulated_object_id ? +simulated_object_id : 'None'}`;
      this.backendApi.exec(
        `root.gate.sim_sensor_set(${params})`
      );
    }
  }

  private getSensorTiggeredId(elementId) {
    const rect = this.getBBox(elementId);
    const rectY = rect.y + rect.height / 2;
    for (const key in this.nodes) {
      if (!this.nodes.hasOwnProperty(key)) {
        continue;
      }

      const node = this.nodes[key];
      const element = document.getElementById(node.id.toString()) as any;
      const rectNode = element.getBBox();

      const isLeftOfSensor = (rectNode.x - rect.x) < 0;
      const diff = Math.abs(rectY - rectNode.y - this.nodeRadius);
      if (isLeftOfSensor && diff - this.nodeRadius < 0) {
        return key;
      }
    }
    return null;
  }

  private updateLightBar() {
    if (!this.lightBar || !this.lightBar.isSimulator) {
      return;
    }

    const lightBar = this.lightBar;
    const hEntry = this.getLightBarInfoH('entry', lightBar.sensorsHEntry);
    const isHEntryChanged = hEntry[0] as boolean;

    const hExit = this.getLightBarInfoH('exit', lightBar.sensorsHExit);
    const isHExitChanged = hExit[0] as boolean;

    const lightBarController = this.appState.lightBarController;

    const isVEntry = this.isLightBarYSensorOn(this.buildLightBarVSensorId(true));
    const isVEntryChanged = isVEntry !== lightBarController.simulationVEntryOn;
    lightBarController.simulationVEntryOn = isVEntry;

    const isVExit = this.isLightBarYSensorOn(this.buildLightBarVSensorId(false));
    const isVExitChanged = isVExit !== lightBarController.simulationVExitOn;
    lightBarController.simulationVExitOn = isVExit;

    if (!isHEntryChanged && !isHExitChanged && !isVEntryChanged && !isVExitChanged) {
      return;
    }

    const hEntryValues = hEntry[1] as boolean[];
    const hExitHValues = hExit[1] as boolean[];

    const f = () => {
      lightBarController.simulationVEntryOn = isVEntry;
      lightBarController.simulationVExitOn = isVExit;
    };

    this.appState.lightBarController.simulateSensors(hEntryValues, hExitHValues, isVEntry, isVExit, f);
  }

  private getLightBarInfoH(name: string, sensors: boolean[]): any[] {
    let changed = false;
    const newValues = new Array(sensors.length);
    for (let i = 0; i < sensors.length; i++) {
      const domId = this.buildLightBarHSensorId(name, i);
      const on = this.isLightBarHSensorOn(domId);
      newValues[i] = on;
      if (on !== sensors[i]) {
        changed = true;
      }
    }
    return [changed, newValues];
  }

  private isLightBarHSensorOn(domId: string): boolean {
    const rect = this.getBBox(domId);
    const lightBarCenterY = rect.y + rect.height / 2;
    for (const key in this.nodes) {
      if (!this.nodes.hasOwnProperty(key)) {
        continue;
      }

      const node = this.nodes[key];
      for (let i = 0; i < node.legs.length; i++) {
        const x = node.legs[i];
        const legCenterY = x.y;
        const diff = Math.abs(lightBarCenterY - legCenterY);
        if (diff < x.radius) {
          return true;
        }
      }
    }
    return false;
  }

  private isLightBarYSensorOn(domId: string): boolean {
    const rect = this.getBBox(domId);
    for (const key in this.nodes) {
      if (!this.nodes.hasOwnProperty(key)) {
        continue;
      }

      const node = this.nodes[key];
      if (Math.abs((rect.y + rect.height / 2) - node.y) < node.radius + rect.height) {
        return true;
      }
    }
    return false;
  }

  private isNodeInsideRect(node: Node, rect): boolean {
    const element = document.getElementById(node.id.toString()) as any;
    const nodeRect = element.getBBox();
    const r = nodeRect.width / 2;
    const x = nodeRect.x + r;
    const y = nodeRect.y + r;
    return (
      x > rect.x &&
      x < rect.x + rect.width &&
      y > rect.y &&
      y < rect.y + rect.height
    );
  }

  private isInsideRect(x: number, y: number, rect): boolean {
    return (
      x > rect.x &&
      x < rect.x + rect.width &&
      y > rect.y &&
      y < rect.y + rect.height
    );
  }

  private deleteNode(id, notifyHost, source) {
    if (!(id in this.nodes)) {
      return;
    }

    const node = this.nodes[id];
    delete this.nodes[id];
    this.d3.selectAll(`circle[id='${id}']`).remove();
    this.d3.selectAll(`text[id='${this.buildLabelId(id)}']`).remove();
    node.legs.forEach(x => this.d3.selectAll(`circle[id='${x.domId}']`).remove());

    this.updateNodesEnvironment();
    console.log(
      `deleted. Id: ${id}. Existing Ids: ${Object.keys(
        this.nodes
      )}. source: '${source}'`
    );
  }

  get nodeRadius(): number {
    return 15;
  }

  private onClick(d3: D3) {
    const scope = this;
    if (d3.event.ctrlKey) {
      return;
    }

    const p = d3.mouse(this.d3Svg.node());
    const cx = p[0];
    const cy = p[1];
    if (!this.canCreateNode(cx, cy)) {
      return;
    }

    const id = ++this.nodeId;
    const node = new Node(id, cx, cy, this.nodeRadius);
    this.nodes[id] = node;

    function drag() {
      const toId = +this.getAttribute('id');
      const to = scope.nodes[toId];

      const x = scope.d3.event.x;
      const y = scope.d3.event.y;
      to.x = x;
      to.y = y;

      if (!scope.canMoveNode(x, y)) {
        return scope.d3.select(this);
      }

      const label = scope.getElementById(scope.buildLabelId(toId));
      if (!label) {
        return;
      }

      {
        const mX = x - +label.getAttribute('x') + nodeTextShiftX;
        const mY = y - +label.getAttribute('y') + nodeTextShiftY;
        const transformAttr = ' translate(' + mX + ',' + mY + ')';
        label.setAttribute('transform', transformAttr);
      }

      Leg.getNames().forEach(name => {
        const domId = Leg.buildDomId(toId, name);
        const d3Leg = scope.getElementById(domId);
        if (d3Leg) {
          const leg = to.legs.find(z => z.domId === domId);
          const mX = leg.x - +d3Leg.getAttribute('cx');
          const mY = leg.y - +d3Leg.getAttribute('cy');
          const transformAttr = ' translate(' + mX + ',' + mY + ')';
          d3Leg.setAttribute('transform', transformAttr);
        }
      });

      scope.updateNodeState(to);

      return scope.d3
        .select(this)
        .attr('cx', x)
        .attr('cy', y);
    }

    const v = this.d3Svg
      .append<SVGCircleElement>('circle')
      .attr('id', id)
      .attr('class', 'gate-node-untracked')
      .attr('r', this.nodeRadius)
      .attr('cx', node.x)
      .attr('cy', node.y)
      .call(d3.drag<SVGCircleElement, any>().on('drag', drag));

    const css = 'gate-node-text';
    this.d3Svg
      .append('text')
      .attr('id', this.buildLabelId(id))
      .attr('x', cx + nodeTextShiftX)
      .attr('y', cy + nodeTextShiftY)
      .attr('text-anchor', 'middle')
      .attr('class', css)
      .text(id.toString());

    const f = (eventName: string, predicate: any) => {
      d3.selectAll(`circle[id='${id}']`).on(eventName, function () {
        d3.event.stopPropagation();
        if (predicate && predicate() || predicate == null) {
          scope.deleteNode((this as any).getAttribute('id'), true, eventName);
        }
      });
    };

    [
      ['click', () => d3.event.ctrlKey],
      // ['dblclick', null]
    ].forEach(x => f(x[0] as string, x[1]));

    const canvasHeight = +this.d3Svg.node().getAttribute('height');
    const stepsCount = 13;
    const posSteps = new Set([0, 1, 4, 5, 8, 9, 12, 13, 16, 17, 20, 21, 24, 25, 28, 29, 32, 33]);


    const getY = (leg: Leg): number => {
      const nodeY = leg.node.y > canvasHeight ? 0 : canvasHeight - leg.node.y;
      const stepWidth = canvasHeight / stepsCount;
      const stepNo = Math.floor(nodeY / stepWidth);
      const isEven = stepNo % 2 === 0;
      const k = isEven ? 1 : - 1;
      const start = isEven ? 0 : 1;
      const stepCurrent = start + k * (nodeY - stepNo * stepWidth) / stepWidth;
      const change = posSteps.has(stepNo) ? 1 : -1;
      const step = change * stepCurrent * stepWidth;
      const kLeftOrRight = leg.isLeft ? 1 : -1;
      const y = leg.node.y - kLeftOrRight * step;
      // console.log(`${nodeY} ${stepWidth} stepNo: ${stepNo} isEven: ${isEven} change: ${change} % ${stepCurrent}  ${step} ${maxStep}`);
      return y;
    };

    Leg.getNames().map(x => new Leg(x, node, getY)).forEach(x => {
      this.d3Svg
        .append<SVGCircleElement>('circle')
        .attr('id', x.domId)
        .attr('class', 'gate-node-untracked')
        .attr('r', x.radius)
        .attr('cx', x.x)
        .attr('cy', x.y);
      node.legs.push(x);
    });

    console.log(`added Id: ${id}. Existing Ids: ${Object.keys(this.nodes)}`);
    setTimeout(() => this.updateNodeState(node), 75);
    return v;
  }

  private updateDoorsLight(gateLight: GateLight) {
    this.gateDoors.forEach((x: GateDoor) => {
      let style = null;
      switch (x.gateDoorEnum) {
        case GateDoorEnum.Unit1:
          style = gateLight.doorMaster;
          break;
        case GateDoorEnum.Unit2:
          style = gateLight.doorSlave;
          break;
      }

      $(`#${x.id}`).attr('style', `${GateLight.buildStyleString(style)}`);
    });
  }

  private getIncrementedCount(count: number): number {
    if (!this.d3.event.ctrlKey) {
      return count + 1;
    }

    if (count > 0) {
      count -= 1;
    }

    return count;
  }
}

