export class GateContact {
  name: string;
  value: boolean;
  simulatable: boolean;
  actionTag: string;
  setFromRemote: boolean;

  constructor(name: string,
    value: boolean,
    simulatable: boolean,
    actionTag: string,
    setFromRemote: boolean,
    ) {
    this.name = name;
    this.value = value;
    this.simulatable = simulatable;
    this.actionTag = actionTag;
    this.setFromRemote = setFromRemote;
  }

  updateFromJson(json: any) {
    this.name = json.name;
    this.value = json.value;
    this.simulatable = json.simulatable;
    this.actionTag = json.action_tag;
    this.setFromRemote = json.set_from_remote;
  }
}
