import { Injectable } from '@angular/core';
import { IBackendApi } from '../../../services/backend-api/backend-api.interface';
import { BackendEventsService } from '../../../services/backend-events/backend-events.service';
import { BackendApiService } from '../../../services/backend-api/backend-api.service';
import { IBackendEvents } from '../../../services/backend-events/backend-events-interface';
import { FileIoTask } from '../models/file-io-task';
import { FileIoTaskStep } from '../models/file-io-task-step';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '../../../services/app-state.service';
import { AppState } from '../../../models/app-state';
import { FileIoDirection } from '../models/file-io-direction';
import { FileUtility } from 'fmcu-core-ng';
import { relativeTimeRounding } from 'moment';
import { FileIoTaskStepState } from '../models/file-io-task-step-state';

const WAIT_TIMEOUT = 500;

@Injectable()
export class FileIoService {
  private backendApi: IBackendApi;
  private backendEvents: IBackendEvents;
  private appState: AppState;
  task: FileIoTask;

  private testTimespanMax = 1000;
  private testTimespan = 0;

  constructor(
    private translate: TranslateService,
    private appStateService: AppStateService,
    private backendApiService: BackendApiService,
    private backendEventsService: BackendEventsService
  ) {
    this.backendApi = backendApiService.backendApi;
    this.backendEvents = backendEventsService.backendEvents;
    this.appState = appStateService.appState;
    this.appState.fileIoTaskStepChange.subscribe(x =>
      this.onFileIoTaskStepChange(x)
    );
    this.appState.fileIoTaskChange.subscribe(x => this.onFileIoTaskChange(x));
    this.appState.fileIoPayloadChanged.subscribe(x =>
      this.onfileIoPayloadChanged(x)
    );
  }

  onFileIoTaskStepChange(step: FileIoTaskStep) {
    if (this.task == null) {
      console.error('task must not be null');
      return;
    }

    this.task.updateStep(step);
  }

  onFileIoTaskChange(json: any) {
    const task = this.task;
    if (!json) {
      this.task = null;
      return;
    }

    if (task && this.task.master) {
      this.task.updateFromJson(json);
    } else {
      this.task = FileIoTask.fromJson(json);
    }
  }

  onfileIoPayloadChanged(value: boolean) {
    if (!value) {
      return;
    }

    this.backendApi.ioGetFile().subscribe(x => {
      FileUtility.saveToFile('init.set', x);
    }, e => console.error(e));
  }

  get inProgress(): boolean {
    return this.task != null && this.task.inProgress;
  }

  startIoTask(task: FileIoTask) {
    if (this.inProgress) {
      console.error('In progress');
      return;
    }

    this.task = task;
     this.backendApi.ioStartTask(task).subscribe(x => {
        const uploadSteps = task.steps.filter(y => y.fileIoDirection === FileIoDirection.Upload);
        setTimeout(() => this.uploadFile(uploadSteps, task.getFile), WAIT_TIMEOUT);
      },
      e => {
        const step = task.steps[0];
        step.info = e.statusText;
        step.state = FileIoTaskStepState.FinishedFailure;
        task.inProgress = false;
        console.log(e.statusText);
      }
    );
  }

  uploadFile(steps: FileIoTaskStep[], getFile: (string) => File) {
    if (!steps.length) {
      return;
    }

    const step = steps.shift();
    const reader = new FileReader();
    reader.onload = () => {
        this.backendApi
          .ioUploadFile(step, reader.result as string)
          .subscribe(() => this.uploadFile(steps, getFile), e => console.error(e));
    };
    reader.readAsDataURL(getFile(step.fileName));
  }

  cancel() {
    this.backendApi.ioCancel().subscribe(x => { }, e => console.log(e));
  }

  clear() {
    this.backendApi.ioClear().subscribe(x => { }, e => console.log(e));
  }
}
