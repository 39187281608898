import { v4 as uuid } from 'uuid';
import { UnitSelection, getUnitSelectionString } from './unit-selection';
import { FileIoType } from './file-io-type';
import { FileIoDirection } from './file-io-direction';
import { FileIoTaskStep } from './file-io-task-step';

export class FileIoTask {
  id = uuid();
  tag: string;
  fileIoType: FileIoType;
  fileIoDirection: FileIoDirection;
  unitSelection: UnitSelection;
  inProgress = true;
  steps: FileIoTaskStep[] = [];
  getFile: (fileName: string) => File;
  master = false;

  constructor(
    tag: string,
    fileIoType: FileIoType,
    fileIoDirection: FileIoDirection,
    unitSelection: UnitSelection,
    steps: FileIoTaskStep[],
    inProgress = true,
    getFile: (fileName: string) => File = null,
    master = false,
  ) {
    this.tag = tag;
    this.fileIoType = fileIoType;
    this.fileIoDirection = fileIoDirection;
    this.unitSelection = unitSelection;
    this.steps = steps;
    this.inProgress = inProgress;
    this.getFile = getFile;
    this.master = master;
  }

  static fromJson(json: any): FileIoTask {
    const tag = json.tag;
    const fileUnitType = json.file_io_type as FileIoType;
    const fileIoDirection = json.file_io_direction as FileIoDirection;
    const fileNames = json.file_names;
    const unitSelection = json.unit_selection as UnitSelection;
    const inProgress = json.in_progress;

    const jsonSteps = json.steps;
    const steps: FileIoTaskStep[] =
      jsonSteps == null ? [] : jsonSteps.map(x => FileIoTaskStep.fromJson(x));
    return new FileIoTask(
      tag,
      fileUnitType,
      fileIoDirection,
      unitSelection,
      steps,
      inProgress,
    );
  }

  static createSteps(fileNames: string[], getRemotePath: (string) => string, fileIoDirection: FileIoDirection = null) {
    const steps = [];
    const step = new FileIoTaskStep('Initializing');
    step.dateStarted = new Date(Date.now());
    steps.push(step);
    fileNames.forEach(x => {
      const s = new FileIoTaskStep(x, x, getRemotePath(x));
      s.fileIoDirection = fileIoDirection;
      steps.push(s);
    });
    return steps;
  }

  updateStep(step: FileIoTaskStep) {
    const s = this.steps.find(x => x.id === step.id);
    if (s == null) {
      console.error(`Step ${step} not found`);
      return;
    }

    s.updateFromOther(step);
  }

  updateFromJson(json: any) {
    if (this.id !== json.id) {
      console.log(`Bad id. Expected id: ${this.id} but reecived ${json.id}`);
    }

    this.inProgress = json.in_progress;
  }

  toString(): string {
    const unitSelection = getUnitSelectionString(this.unitSelection);
    return `<${this.fileIoDirection}> (<${this.fileIoType}>, <${unitSelection}>)`;
  }

  get progress(): number {
    const len = this.steps.length;
    const steps = this.steps.slice(1, len);
    return  Math.ceil(steps.reduce((x, y) => x + y.progress, 0) / (len - 1));
  }

  get done(): boolean {
    return this.progress === 100;
  }
}
