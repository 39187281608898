export class OpenVPNStatus {
    statusText: String;
    isActive: boolean;
    isEnabled: boolean;
    ipv4Address: String;
    files: [String];

    updateFromJson(json: any) {
        this.statusText = json.status_text;
        this.isActive = json.is_active;
        this.isEnabled = json.is_enabled;
        this.ipv4Address = json.ipv4_address;
        this.files = json.files;
    }
}
