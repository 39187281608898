import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Utils } from './utils';

const TRANSLATABLE_FIELDS = [
  'label',
];

export class Translator {
  private _translateService: TranslateService;
  private translatableFields: string[];

  constructor(translateService: TranslateService, translatableFields: string[] = null) {
    this.translatableFields = translatableFields != null ? translatableFields : TRANSLATABLE_FIELDS;
    this._translateService = translateService;
    translateService.onLangChange.subscribe((event: LangChangeEvent) =>
      this.translate()
    );
  }

  protected translate() {}

  protected translateObj(obj) {
    if (obj instanceof Array) {
      obj.forEach(x => this.translateObj(x));
    } else if (Utils.isDictionary(obj)) {
      for (const k in obj) {
        if (!obj.hasOwnProperty(k) || k.startsWith('_')) {
          continue;
        }
        const v = obj[k];
        if (Utils.isDictionary(v) || v instanceof Array) {
          this.translateObj(v);
        } else if (typeof v === 'string' && this.translatableFields.includes(k)) {
          this.translateValue(obj, k);
        }
      }
    }
  }

  private translateValue(dict: any, key: string) {
    const value = dict[key];
    if (!value) {
      return;
    }
    const keyDefault = key + 'Default';
    let valueDefault = dict[keyDefault];
    if (valueDefault == null) {
      valueDefault = value;
      dict[keyDefault] = valueDefault;
    }
    dict[key] = this._translateService.instant(valueDefault);
  }
}
