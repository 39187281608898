import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RolesService } from 'src/app/services/backend-api/roles.service';
import { FileIoService } from '../modules/weac/services/file-io-service';

@Injectable()
export class FirstSetupStepsStateService {
  updateInProgress = false;
  isNetworkChanged = false;
  _isNetworkDirty: boolean;


  constructor(
    private fileIoService: FileIoService,
    private router: Router,
    private rolesService: RolesService,
  ) {
  }

  get isUpdateInProgress(): boolean {
    return (this.updateInProgress && '/configuration' === this.router.url && this.rolesService.canAccessResource('/configuration', 'diamex-update'))
      || (this.fileIoService.inProgress && '/weac' === this.router.url
        && (this.rolesService.canAccessResource('/weac', 'firmware')
          || this.rolesService.canAccessResource('/weac', 'door'))
      );
  }

  startUpdate() {
    this.updateInProgress = true;
  }

  endUpdate() {
    this.updateInProgress = false;
  }

  get isNetworkDirty() {
    return this._isNetworkDirty;
  }

  set isNetworkDirty(value) {
    this._isNetworkDirty = value;
  }

}
